<template>
  <div class="flex-form">
    <el-form
      ref="paymentForm"
      v-loading="loading"
      :model="paymentForm"
      label-width="170px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Payment
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Payment
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Uid"
      >
        {{ paymentUid }}
      </el-form-item>

      <el-form-item
        label="Active"
        prop="active"
      >
        <el-switch
          v-model="paymentForm.isActive"
        />
      </el-form-item>

      <el-form-item
        label="Name"
        prop="name"
      >
        <el-input v-model="paymentForm.name" />
      </el-form-item>

      <el-form-item
        label="Position"
        prop="position"
      >
        <el-input v-model="paymentForm.position" />
      </el-form-item>

      <el-form-item
        label="Logo"
      >
        <upload-media
          ref="logoImage"
          :image-url="logoUrl"
          :params="{ contentType: 'PAYMENT_LOGO' }"
          @success="handleLogoUploadSuccess"
          @remove="handleLogoUploadRemove"
        />
      </el-form-item>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getShopPayment,
  updateShopPayment,
  createShopPayment,
} from '@/api/shopPayment';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import UploadMedia from '@/components/UploadMedia';

export default {
  name: 'ShopPaymentDetails',
  components: { UploadMedia },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    paymentUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      paymentForm: {
        name: '',
        position: 0,
        isActive: true,
      },
      payment: {},
      rules: {
        name: [{
          required: true,
          trigger: 'blur',
          message: 'Name is required',
        }],
        position: [{
          required: true,
          trigger: 'blur',
          message: 'Position is required',
        }],
      },
    };
  },
  computed: {
    logoUrl() {
      return this.paymentForm?.media?.logo?.image?.location;
    },
  },
  watch: {
    visible() {
      if (this.paymentUid) {
        this.getPayment(this.paymentUid);
      } else {
        this.initForm();
      }
    },
  },
  async created() {
    if (this.isEditMode) {
      await this.getPayment(this.paymentUid);
    } else {
      await this.initForm();
    }
  },
  methods: {
    async initForm() {
      this.resetForm();
    },
    async getPayment(paymentUid) {
      try {
        this.resetForm();
        this.loading = true;

        const data = await getShopPayment({ paymentUid });

        this.payment = cloneDeep(data.shopPayment);
        this.paymentForm = data.shopPayment;

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.onCancel();

        throw e;
      }
    },
    async onEditSubmit() {
      this.$refs.paymentForm.validate(async (valid, errors) => {
        if (valid) {
          const updatedPayment = makeUpdateObj(this.payment, this.paymentForm);
          if (Object.keys(updatedPayment).length > 0) {
            try {
              this.saving = true;
              updatedPayment.paymentUid = this.paymentUid;

              await updateShopPayment(updatedPayment);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              this.saving = false;

              this.$emit('update');
              this.close();
            } catch (e) {
              this.saving = false;
            }
          }
        } else {
          this.$message({
            type: 'error',
            message: errors[Object.keys(errors)[0]][0].message,
          });
        }
      });
    },
    async onAddSubmit() {
      this.$refs.paymentForm.validate(async (valid, errors) => {
        if (valid) {
          try {
            this.saving = true;
            await createShopPayment(this.paymentForm);

            this.$message({
              type: 'success',
              message: 'Payment has been added successfully',
            });

            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        } else {
          this.$message({
            type: 'error',
            message: errors[Object.keys(errors)[0]][0].message,
          });
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    resetForm() {
      this.payment = {};

      this.$nextTick(() => {
        this.$refs.paymentForm.resetFields();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    handleLogoUploadSuccess(data) {
      this.paymentForm.logoMediaUid = data.mediaUid;
    },
    handleLogoUploadRemove() {
      this.paymentForm.logoMediaUid = null;
      this.paymentForm.media.logo = null;
    },
  },
};
</script>
